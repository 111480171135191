.notification-content {
  .timer {
    width: 0;
  }
}

.timer {
  &__actions {
    margin-top: 5px;

    .btn {
      outline: none;
      background-color: #c79604;
      padding: 4px 10px;
      border: none;
      color: white;
      border-radius: 4px;

      &:hover {
        background-color: #ba8c04;
        color: white;
      }

      &:nth-last-of-type(1) {
        margin-left: 10px;
      }
    }
  }
}
