.notification {
  font-size: 14px;
  width: 100%;
  display: flex;
  margin: 0;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 30px 5px 0;
  }

  .icon_wrapper {
    padding: 16px;
    margin-right: 16px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {

    }
  }

  .success {
    background-color: rgba(69, 182, 126, 0.12);
  }

  .failure {
    background-color: rgba(239, 81, 81, 0.12);
  }

  .drawn {
    background-color: rgba(201, 162, 5, 0.12);
  }

  &_icon {
    opacity: 1;
  }

  &_close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}
