.card {
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #bdbdbd;
  background-color: #ffff;
  margin-right: 25px;
  display: flex;
  align-items: center;

  &__title {
    font-size: 19px;
    font-weight: 500;
    color: #2296f3;
    margin-right: 15px;
  }

  &__value {
    display: flex;
    align-items: baseline;
    font-size: 1.9rem;

    &_flex {
      margin-left: 4px;
      color: #bdbdbd;
      font-size: 1.5rem;
    }
  }

  &__details {
    margin-top: 4px;
    color: #646464;
  }
}
